import React, { useState } from 'react';
import './App.css';
import PodcastList from './PodcastList';
import ArtworkList from './ArtworkList';

function App() {
  const [showPodcast, setShowPodcast] = useState(false);
  const [showArtworks, setShowArtworks] = useState(false);
  const [showBlog, setShowBlog] = useState(false);

  const handlePodcastClick = () => {
    setShowPodcast(!showPodcast);
    setShowArtworks(false);
    setShowBlog(false);
  };

  const handleArtworkClick = () => {
    setShowArtworks(!showArtworks);
    setShowPodcast(false);
    setShowBlog(false);
  };

  const handleBlogClick = () => {
    setShowBlog(!showBlog);
    setShowPodcast(false);
    setShowArtworks(false);
  };

  return (
    <div className="App">
      {/* メインビジュアルセクション */}
      <section className="main-visual">
        <div className="overlay">
          <div className="text-content">
            <h1>エメリア電波塔</h1>
            <p>どこかにひっそりと佇む電波塔へようこそ</p>
            <div className="divider"></div>
            <div className="button-links">
              <button onClick={handlePodcastClick} className="button">Podcast</button>
              <button onClick={handleArtworkClick} className="button">Artworks</button>
              <button onClick={handleBlogClick} className="button">Blog</button>
            </div>
          </div>
        </div>
      </section>

      {/* ポッドキャスト表示 */}
      {showPodcast && (
        <div className="podcast-container fade-in">
          <PodcastList />
        </div>
      )}

      {/* アートワーク表示 */}
      {showArtworks && (
        <div className="artworks-container fade-in">
          <ArtworkList />
        </div>
      )}

      {/* Blog表示 */}
      {showBlog && (
        <div className="blog-container fade-in">
          <h3>メンテナンス中…</h3>
        </div>
      )}

      {/* 右下のソーシャルアイコン */}
      <div className="social-icons">
        <a href="https://twitter.com/Ar_714g" target="_blank" rel="noopener noreferrer" className="icon twitter">
          <i className="fab fa-twitter"></i>
        </a>
        <a href="https://www.mixcloud.com/Akisame_Ar/" target="_blank" rel="noopener noreferrer" className="icon mixcloud">
          <i className="fab fa-mixcloud"></i>
        </a>
        <a href="https://www.youtube.com/@Ar-guiter" target="_blank" rel="noopener noreferrer" className="icon youtube">
          <i className="fab fa-youtube"></i>
        </a>
        <a href="https://vrchat.com/home/group/grp_9eff3253-42ef-48ad-84e8-74856d0c3d5f" target="_blank" rel="noopener noreferrer" className="icon vrchat">
          <img src="/logo/vrchat-logo.svg" alt="VRChat" className="icon-image" />
        </a>
      </div>
    </div>
  );
}

export default App;
